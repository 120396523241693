<template>
    <modal ref="modalLeecheros" :titulo="`Selecciona tu ${$config.vendedor}`" no-adicional no-cancelar>
        <div class="row mx-0 j-center">
            <div v-for="(data, idx) in listaLeecheros" :key="idx" class="col-8 mb-3 cr-pointer">
                <div :class="`card-leechero${data.leechero_asignado != null ? '-activo' : ''} p-2 d-flex`" @click="modalDetalleLeechero( data.id )">
                    <img :src="data.logo" class="br-6 obj-cover" width="76" height="76" />
                    <div class="col">
                        <p class="f-16 tres-puntos">
                            {{ data.propietario_nombre }}
                        </p>
                        <p class="f-12 tres-puntos text-gris2">
                            {{ data.identificador }}
                        </p>
                        <div class="d-flex mt-2">
                            <div class="col-auto px-2 d-flex">
                                <i class="icon-star text-yellow" />
                                <span class="f-500 ml-2">{{ data.calificacion }}</span>
                            </div>
                            <div v-if="data.productos_carrito_cant != 0" class="pill-cart mr-1">
                                <i class="icon-cart-arrow-down f-18" />
                                <span class="f-500 ml-2">{{ data.productos_carrito_cant }}</span>
                            </div>
                            <div v-if="data.pedidos_cant != 0" class="pill-order">
                                <i class="icon-order f-18 text-light" />
                                <span class="f-500 ml-2">{{ data.pedidos_cant }}</span>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div v-if="data.leechero_asignado == null && $usuario.tipo_proyecto == 2" class="float-right" @click="modalEliminar( data.id)">
                    <i class="icon-trash-can-outline f-17 text-red ml-2" />
                </div>
            </div>
        </div>
        <div v-if="$usuario.tipo_proyecto == 2" class="row mx-0 mt-5 j-center">
            <div class="col-auto">
                <div class="btn-general px-3" @click="irAnadirLeechero">
                    Añadir nuevo {{ $config.vendedor }}
                </div>
                <p class=" f-14 text-center f-500 mt-3" @click="irAnadirLeechero">
                    ¿Qué es un {{ $config.vendedor }}?
                </p>
            </div>
        </div>
    </modal>
</template>

<script>
import Leechero from '~/services/leechero/leechero'
export default {
    data(){
        return {
            id_tienda: '',
            id_tendero: '',
            listaLeecheros:[],
            leechero_activo: null,
        }
    },
    mounted(){
        
    },
    methods: {
        toggle(){
            this.$refs.modalLeecheros.toggle();
            this.listLeecheros()
        },
        modalDetalleLeechero(item){
            this.id_tendero = item
            this.$emit("detalleLeechero", this.id_tendero);
            this.$refs.modalLeecheros.toggle();
        },
        modalEliminar(item){
            this.id_tienda = item
            this.$emit("eliminar", this.id_tienda);
            this.$refs.modalLeecheros.toggle();
        },
        async listLeecheros(){
            try {
                const { data } = await Leechero.getListLeechero();
                this.listaLeecheros = data.listaLeecheros;
            } catch (e){
                this.errorCatch(e)
            }          
        },
        irAnadirLeechero(){
            this.$refs.modalLeecheros.toggle();
            this.$router.push({name: 'tienda.agregar'});
        }
    }
}
</script>
<style lang="scss" scoped>
.card-leechero{
    width: 312px;
    height: 96px;
    box-shadow: 0px 3px 6px #0000000D;
    cursor: pointer;
    border-radius: 8px;
    &-activo{
        @extend .card-leechero;
        border: 1px solid var(--color-general) !important;
    }
}
.pill-cart{
    width: 50px;
    height: 27px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #29D884;
    box-shadow: 0px 2px 6px #0000000D;

}
.pill-order{
    width: 50px;
    height: 27px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    box-shadow: 0px 2px 6px #0000000D;

}
.float-right{
  position:absolute;
  right:0;
  top:50%;
  transform:translateY(-50%);
}
</style>